import './ContactUsForm.scss';

import React from 'react';

/* eslint-disable */

const states =
    [
      'Alabama',        'Alaska',       'Arizona',      'Arkansas',
      'California',     'Colorado',     'Connecticut',  ' Delaware',
      'Florida',        'Georgia',      'Hawaii',       'Idaho',
      'Illinois',       'Indiana',      'Iowa',         'Kansas',
      'Kentucky',       'Louisiana',    'Maine',        'Maryland',
      'Massachusetts',  'Michigan',     'Minnesota',    'Mississippi',
      'Missouri',       'Montana',      'Nebraska',     'Nevada',
      'New Hampshire',  'New Jersey',   'New Mexico',   'New York',
      'North Carolina', 'North Dakota', 'Ohio',         'Oklahoma',
      'Oregon',         'Pennsylvania', 'Rhode Island', 'South Carolina',
      'South Dakota',   'Tennessee',    'Texas',        'Utah',
      'Vermont',        'Virginia',     'Washington',   'West Virginia',
      'Wisconsin',      'Wyoming'
    ]

    class ContactUsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nameValid: true,
      emailValid: true,
      messageValid: true,
      countryValid: true,
      isValid: true,
      country: ''
    };
  }
  submitForm =
      () => {
        const form = document.getElementsByName('Contact Us Form')[0];
        const isValid = this.validate();
        if (isValid) {
          const submitBtn = document.getElementById('form-submit-btn');
          submitBtn.click();
        }
      }

  validate =
      () => {
        let isValid = true;
        let stateChange = {
          nameValid: true,
          emailValid: true,
          messageValid: true,
          isValid: true,
        };
        const name = document.getElementById('ContactUsForm--name');
        const email = document.getElementById('ContactUsForm--email');
        const message = document.getElementById('ContactUsForm--message');


        if (name.value === '') {
          isValid = false;
          stateChange.nameValid = false;
        }
        if (email.value === '') {
          isValid = false;
          stateChange.emailValid = false;
        } else {
          const emailRegex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          const isValidEmail = emailRegex.test(email.value);
          if (!isValidEmail) {
            isValid = false;
            stateChange.emailValid = false;
          }
        }
        if (message.value === '') {
          isValid = false;
          stateChange.messageValid = false;
        }
        stateChange.isValid = isValid;
        this.setState(stateChange);
        return isValid;
      }


  onChangeName =
      () => {
        if (!this.state.emailValid) {
          this.setState({
            nameValid: true,
            isValid: true,
          });
        }
      }

  onChangeEmail =
      () => {
        if (!this.state.emailValid) {
          this.setState({
            emailValid: true,
            isValid: true,
          });
        }
      }

  onChangeMessage =
      () => {
        if (!this.state.messageValid) {
          this.setState({
            messageValid: true,
            isValid: true,
          });
        }
      }

  onChangeCountry =
      () => {
        if (!this.state.countryValid) {
          this.setState({countryValid: true, isValid: true})
        }
      }

  handleSelect =
      (e) => {
        this.setState({country: 'United States'})
      }

  handleCountry =
      (e) => {
        this.setState({country: e.target.value})
      }


  render() {
    const {
      nameValid,
      emailValid,
      messageValid,
      isValid,
    } = this.state;
    let formName = 'Contact Us Form';
    switch (window.location.hostname) {
      case 'www.selectcannabis.com':
        break;
      case 'release.selectcannabis.com':
        formName += ' - Release';
        break;
      case 'develop.selectcannabis.com':
      default:
        formName += ' - Develop';
    }
    return (
      <div className='ContactUsForm'>
      </div>
    );
        }
        }

        export default ContactUsForm;
