import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { TEXT_TYPE } from '../../types/textTypes';

import Text from '../Text';
import Button from '../Button';
import HubspotForm from '../Hubspot/hubspotForm.tsx';
import Logo from '../Logo';

import './Footer.scss';
import SignUpForm from '../SignUpForm/SignUpForm';
import ContactUsForm from '../ContactUsForm';

class Footer extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    view: PropTypes.string,
  };

  static defaultProps = {
    show: '',
    view: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
    if (/Android/.test(navigator.appVersion)) {
      window.addEventListener('resize', () => {
        if (
          document.activeElement.tagName === 'INPUT' ||
          document.activeElement.tagName === 'TEXTAREA'
        ) {
          document.activeElement.scrollIntoView();
        }
      });
    }
  }

  onClickTos = event => {
    event.preventDefault();
    navigate('/tos');
  };

  openFaq = event => {
    // window.open("https://www.selectcannabis.com/faq/");
    event.preventDefault();
    navigate('/faq');
  };

  onClickContactUs = event => {
    event.preventDefault();
    // navigate('/tos');
  };

  toggleForm = () => {
    const { showForm } = this.state;
    this.setState({
      showForm: !showForm,
    });
    if (showForm) {
      document.body.classList.remove('noScroll');
      document.body.classList.remove('modal-footer');
    } else {
      document.body.classList.add('noScroll');
      document.body.classList.add('modal-footer');
    }
  };

  openIG = () => {
    window.open('https://www.instagram.com/select.better/');
  };

  onClickCareers = () => {
    window.open('https://curaleaf.com/careers/');
  };

  render() {
    const { show, view } = this.props;
    const { showForm } = this.state;
    return (
      <footer className={`Footer ${show ? 'show' : ''} ${view}`}>
        <div className="Footer--container container">
          <SignUpForm view={view} />
          <div className="Footer--legal">
            <div className="Footer--legal--links">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Text
                  className="Footer--legal--links--careers"
                  size={TEXT_TYPE.legal}
                  tag="div"
                  value="Careers"
                  onClick={this.onClickCareers}
                />
                <Text
                  className="Footer--legal--links--contact"
                  size={TEXT_TYPE.legal}
                  tag="div"
                  value="Contact Us"
                  onClick={this.toggleForm}
                />
                <Text
                  className="Footer--legal--links--instagram"
                  size={TEXT_TYPE.legal}
                  tag="div"
                  value="Instagram"
                  onClick={this.openIG}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Text
                  className="Footer--legal--links--tos"
                  size={TEXT_TYPE.legal}
                  tag="div"
                  value="Terms Of Service"
                  onClick={this.onClickTos}
                />
              </div>
              {/* <Text
                className="Footer--legal--links--faq"
                size={TEXT_TYPE.legal}
                tag="div"
                value="FAQ"
                onClick={this.openFaq}
              /> */}
            </div>

            <Text
              className="Footer--legal--copyright"
              size={TEXT_TYPE.legal}
              tag="div"
              value={`© ${new Date().getFullYear()} Cura CS.`}
            />
            <p className="Footer--legal--disclaimer">
              For use only by adults twenty-one years of age and older (For use
              by 18+ in limited states). Do not operate a vehicle or machinery
              while under the influence of this drug. Keep out of reach of
              children. Cura, CA CDPH-10001355 | Curaleaf, CA CDPH-10003298
            </p>
            <p className="Footer--legal--disclaimer">
              For Massachusetts: Please Consume Responsibly. This product has
              not been analyzed or approved by the Food and Drug Administration
              (FDA). There is limited information on the side effects of using
              this product, and there may be associated health risks. Marijuana
              use during pregnancy and breast-feeding may pose potential harms.
              It is against the law to drive or operate machinery when under the
              influence of this product. KEEP THIS PRODUCT AWAY FROM CHILDREN.
              There may be health risks associated with consumption of this
              product. Marijuana can impair concentration, coordination, and
              judgment. The impairment effects of Edible Marijuana Products may
              be delayed by two hours or more. In case of accidental ingestion,
              contact poison control hotline 1-800-222-1222 or 911. This product
              may be illegal outside of MA.
            </p>
          </div>
        </div>
        {showForm && (
          <div className="Footer--form">
            <div className="Footer--form--container">
              <Button
                className="Footer--form--container--close-cta"
                isTransparent
                hasUnderline
                color="black"
                onClick={this.toggleForm}
              >
                Close
              </Button>
              <h2>
                <Logo className="invert" />
              </h2>
              <h3 className="SignUpForm--title" style={{ margin: 0 }}>
                We're always working on something new. Sign up for our
                newsletter to stay up to date on all the latest brand and
                product news!
              </h3>
              <HubspotForm
                id="general"
                formId="4b3967d0-170e-43b0-b9e4-c8918ca10a62"
              />
              <ContactUsForm />
            </div>
            <div className="Footer--rainbowStrip" />
          </div>
        )}
      </footer>
    );
  }
}

export default Footer;
